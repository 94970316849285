<template>
  <organisms-site-footer
    v-if="footerData"
    variant="extralight"
    :legal-links="footerData"
    :open-cookies="openCookies"
  />
</template>

<script lang="ts">
import { mapGetters } from 'vuex'

export default defineNuxtComponent({
  name: 'AprilFooter',
  computed: {
    ...(mapGetters({ footerData: 'prismic/footerData' }) as any),
  },
  async asyncData({ $prismic, $store }) {
    const footerPrismic = await $prismic.client.getSingle('tgps_footer')

    if (footerPrismic) {
      const formattedData = {
        title: $prismic.asHTML(footerPrismic.data?.title),
        links:
          footerPrismic.data?.links?.map(
            (link: {
              label: any
              link: { url: any; link_type: string }
              cookies_opener: any
            }) => ({
              label: link.label,
              link: {
                href: link.link.url,
                external: (link.link.link_type === 'Web').toString(),
                target: '_blank',
              },
              cookiesOpener: link.cookies_opener,
            })
          ) || [],
      }

      $store.dispatch('prismic/setFooterData', formattedData)
    }
  },
  methods: {
    openCookies() {
      if (import.meta.client) {
        ;(window as any)._axcb.push(function (sdk: any) {
          const firstInput = sdk.overlayNode.querySelector('input')
          !firstInput && sdk.openCookies()
        })
      }
    },
  },
})
</script>

<style lang="scss">
.site-footer {
  margin-top: 2rem;

  @media #{$mq-medium} {
    margin-top: 7.5rem;
  }
}
</style>
