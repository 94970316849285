
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as PrismicPreviewOcMDyPIjGheJ09J_450PKOgW2wXAfUzgHKqquyWIcmSYcMeta } from "/vercel/path0/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue?macro=true";
import { default as _91orderId_93IdIWERZVDcgeB9pwLYIU0Cy3Mr7o5dSAvbMeD5IeLbIMeta } from "/vercel/path0/pages/adhesion-validee/[orderId].vue?macro=true";
import { default as adresseu2Rt9FAZk0WRZxm_45HdhKArFYxyaZCFGGS64eeQWV6Z4Meta } from "/vercel/path0/pages/adresse.vue?macro=true";
import { default as assureur_45a_45resilierdXUG3d_JHyABPNFYtP_lxa3pyOb_Ovn4vflviQwYbL0Meta } from "/vercel/path0/pages/assureur-a-resilier.vue?macro=true";
import { default as confirmation_45smsud5jsdo6H7sPyv29UiJCG3LM_458TEBKbrtppAnEj7nzwMeta } from "/vercel/path0/pages/confirmation-sms.vue?macro=true";
import { default as conjointZLxVf3ngdyo8Nl4FMDsN_8C2Q_45r_45nuj52QZNfIsJKk0Meta } from "/vercel/path0/pages/conjoint.vue?macro=true";
import { default as contact6cZoOGxJBTY9l_45DSf37OWQKprIBU1PffJx7PVn14dCsMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as couverture_45actuellel_45EpGdV5lJHSG7CGZ6HEdY_45Y3PZoLC2knivkWfOwjJwMeta } from "/vercel/path0/pages/couverture-actuelle.vue?macro=true";
import { default as date_45effetEglYbvGg61jaTWkSEYcqU7JJZ3OPmo1mXRU6vSdhN84Meta } from "/vercel/path0/pages/date-effet.vue?macro=true";
import { default as date_45naissanceF3L1DgZqTCW98AWcdN5yzEK5LZx8yqNlOUnZhO2_AecMeta } from "/vercel/path0/pages/date-naissance.vue?macro=true";
import { default as detail_45adresseWFQ_4566_45BzAk3DEMas4ljCNvpji5R0Yl7vOyDoY0Vp_45kMeta } from "/vercel/path0/pages/detail-adresse.vue?macro=true";
import { default as _projectIdOowYjqwTnPDZakc3YlpEu2nmK5n5OH_DOOxE30o6WWYMeta } from "/vercel/path0/pages/devis/_projectId.vue?macro=true";
import { default as _91projectId_93_45mKEf0xDOsNgE03Q_M_45Qc1Kjly8FjMrLyVLQBKpTXkYMeta } from "/vercel/path0/pages/devis/[projectId].vue?macro=true";
import { default as _91orderId_93NaLghfYCZIoLRIth8Zbi_45tH9Pb_qYlC7dZyYRCxPpxQMeta } from "/vercel/path0/pages/emailconfirmation/[orderId].vue?macro=true";
import { default as enfants_wksEuU5Enu260zjR7IAugSGWmjy5NzwKzhuixBFCIoMeta } from "/vercel/path0/pages/enfants.vue?macro=true";
import { default as familleYhpIU9F8uc_YaSRtVqkYBxieM_45VWpdOEnjjKFt4QwXoMeta } from "/vercel/path0/pages/famille.vue?macro=true";
import { default as ibant1byXZP79EMtz4V8MpbJD_A1ANY0OfU6uQ2E__45JUcNkMeta } from "/vercel/path0/pages/iban.vue?macro=true";
import { default as madelinwgKQ7Yx4mSHlRlmp7wCM3fGl8u09OAedQmEiurzF_twMeta } from "/vercel/path0/pages/madelin.vue?macro=true";
import { default as niveaux_45couvertureRAEdgeB86sdZGkRpaXWqD8ak7_45Pq58SgOF5B8_uxxQgMeta } from "/vercel/path0/pages/niveaux-couverture.vue?macro=true";
import { default as _91projectId_93L4SHW1KqPCp87iVBDkwar1sb4rL54ttajhEfShgtf3IMeta } from "/vercel/path0/pages/offres/[projectId].vue?macro=true";
import { default as indexhIuDuZ19eCB_45CwzmYDui8I1WoUEUccmj_FoKCCGJYqcMeta } from "/vercel/path0/pages/offres/index.vue?macro=true";
import { default as prelevements7EdE_gliIXQHuJLbjW0ZOypmND8yEufGg4oyAO5ifAYMeta } from "/vercel/path0/pages/prelevements.vue?macro=true";
import { default as regime6h3wIWk8SFke6GC2ASqFKmJIpDu_T1pjyPMV_45LUNR9kMeta } from "/vercel/path0/pages/regime.vue?macro=true";
import { default as souscription_45assure_45principal8DHE2H0n3pvvT7Je7Ueiveo3QBEAqnESNieLHcJzXK8Meta } from "/vercel/path0/pages/souscription-assure-principal.vue?macro=true";
import { default as souscription_45conjointc1Ix_456NW_6KlxABqmxP2NJ7GTvFjAG6DlvvXsXLRQusMeta } from "/vercel/path0/pages/souscription-conjoint.vue?macro=true";
import { default as _91page_93o1_45O_PYTbyZAJwH3Q_3w9kkJP1D6NoSKb26GYLjiuJEMeta } from "/vercel/path0/pages/souscription-enfant/[page].vue?macro=true";
import { default as _91projectId_93vVDd6b3mVgegZzeggmweL765_45dH5nVohJSNbRSPy_45OwMeta } from "/vercel/path0/pages/souscription/[projectId].vue?macro=true";
import { default as statut_45proWOlrr3JC1ftYbE5hvNMCrkwqDCFGF7AzZvtG1JSkrV0Meta } from "/vercel/path0/pages/statut-pro.vue?macro=true";
import { default as testqjsskmYe4C6ARrH52MZQwc40dJZ_XoBicua2duVTK68Meta } from "/vercel/path0/pages/test.vue?macro=true";
export default [
  {
    name: "prismic-preview",
    path: "/preview",
    component: () => import("/vercel/path0/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue")
  },
  {
    name: "adhesion-validee-orderId___fr-fr",
    path: "/adhesion-validee/:orderId()",
    component: () => import("/vercel/path0/pages/adhesion-validee/[orderId].vue")
  },
  {
    name: "adresse___fr-fr",
    path: "/adresse",
    component: () => import("/vercel/path0/pages/adresse.vue")
  },
  {
    name: "assureur-a-resilier___fr-fr",
    path: "/assureur-a-resilier",
    component: () => import("/vercel/path0/pages/assureur-a-resilier.vue")
  },
  {
    name: "confirmation-sms___fr-fr",
    path: "/confirmation-sms",
    component: () => import("/vercel/path0/pages/confirmation-sms.vue")
  },
  {
    name: "conjoint___fr-fr",
    path: "/conjoint",
    component: () => import("/vercel/path0/pages/conjoint.vue")
  },
  {
    name: "contact___fr-fr",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "couverture-actuelle___fr-fr",
    path: "/couverture-actuelle",
    component: () => import("/vercel/path0/pages/couverture-actuelle.vue")
  },
  {
    name: "date-effet___fr-fr",
    path: "/date-effet",
    component: () => import("/vercel/path0/pages/date-effet.vue")
  },
  {
    name: "date-naissance___fr-fr",
    path: "/date-naissance",
    component: () => import("/vercel/path0/pages/date-naissance.vue")
  },
  {
    name: "detail-adresse___fr-fr",
    path: "/detail-adresse",
    component: () => import("/vercel/path0/pages/detail-adresse.vue")
  },
  {
    name: "devis-_projectId___fr-fr",
    path: "/devis/_projectId",
    component: () => import("/vercel/path0/pages/devis/_projectId.vue")
  },
  {
    name: "devis-projectId___fr-fr",
    path: "/devis/:projectId()",
    component: () => import("/vercel/path0/pages/devis/[projectId].vue")
  },
  {
    name: "emailconfirmation-orderId___fr-fr",
    path: "/emailconfirmation/:orderId()",
    component: () => import("/vercel/path0/pages/emailconfirmation/[orderId].vue")
  },
  {
    name: "enfants___fr-fr",
    path: "/enfants",
    component: () => import("/vercel/path0/pages/enfants.vue")
  },
  {
    name: "famille___fr-fr",
    path: "/famille",
    component: () => import("/vercel/path0/pages/famille.vue")
  },
  {
    name: "iban___fr-fr",
    path: "/iban",
    component: () => import("/vercel/path0/pages/iban.vue")
  },
  {
    name: "madelin___fr-fr",
    path: "/madelin",
    component: () => import("/vercel/path0/pages/madelin.vue")
  },
  {
    name: "niveaux-couverture___fr-fr",
    path: "/niveaux-couverture",
    component: () => import("/vercel/path0/pages/niveaux-couverture.vue")
  },
  {
    name: "offres-projectId___fr-fr",
    path: "/offres/:projectId()",
    component: () => import("/vercel/path0/pages/offres/[projectId].vue")
  },
  {
    name: "offres___fr-fr",
    path: "/offres",
    component: () => import("/vercel/path0/pages/offres/index.vue")
  },
  {
    name: "prelevements___fr-fr",
    path: "/prelevements",
    component: () => import("/vercel/path0/pages/prelevements.vue")
  },
  {
    name: "regime___fr-fr",
    path: "/regime",
    component: () => import("/vercel/path0/pages/regime.vue")
  },
  {
    name: "souscription-assure-principal___fr-fr",
    path: "/souscription-assure-principal",
    component: () => import("/vercel/path0/pages/souscription-assure-principal.vue")
  },
  {
    name: "souscription-conjoint___fr-fr",
    path: "/souscription-conjoint",
    component: () => import("/vercel/path0/pages/souscription-conjoint.vue")
  },
  {
    name: "souscription-enfant-page___fr-fr",
    path: "/souscription-enfant/:page()",
    component: () => import("/vercel/path0/pages/souscription-enfant/[page].vue")
  },
  {
    name: "souscription-projectId___fr-fr",
    path: "/souscription/:projectId()",
    component: () => import("/vercel/path0/pages/souscription/[projectId].vue")
  },
  {
    name: "statut-pro___fr-fr",
    path: "/statut-pro",
    component: () => import("/vercel/path0/pages/statut-pro.vue")
  },
  {
    name: "test___fr-fr",
    path: "/test",
    component: () => import("/vercel/path0/pages/test.vue")
  }
]