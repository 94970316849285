export default defineNuxtPlugin(() => {
  window.axeptioSettings = {
    clientId: '5fa90751c594b151a13f762d',
    userCookiesDomain: 'april.fr',
  }

  const script = document.createElement('script')
  script.src = 'https://static.axept.io/sdk-slim.js'
  script.async = true
  document.getElementsByTagName('head')[0].appendChild(script)
})
