import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_Tkghm6qboZyK83jq3ydlDUVT7O0Cb00E6XcepuVj9_c from "/vercel/path0/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag from "/vercel/path0/.nuxt/sentry-client-config.mjs";
import plugin_client_RuJQXitzGnmqc_cl4mDZoDvC8sTQFiXAObIrP1fN_lk from "/vercel/path0/node_modules/@nuxtjs/prismic/dist/runtime/plugin.client.js";
import plugin_dZwLP3pabhznAX15ySw1eaAQmwVOMPFRcNoLlER2dWI from "/vercel/path0/node_modules/@nuxtjs/prismic/dist/runtime/plugin.js";
import switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_7DB4Q_rSg7Y6_r2xTJAR9sj0Plzl7GeeI8C3uqDzeqY from "/vercel/path0/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import axeptio_d5yAA7RxpEzZF2ksZVjyFf4Sc3_ts8XFgqrGcUsQOCo from "/vercel/path0/plugins/axeptio.ts";
import gtm_client_MQaOARgMghyVgOHvZC5M5hQI1nTisUfzlPHTlOKu0TA from "/vercel/path0/plugins/gtm.client.ts";
import cookies_sisHiBOjzAv5IPvFgVIiOxXz4TLhr96GevWdTx4s_U4 from "/vercel/path0/plugins/cookies.ts";
import vuex_wQowj8nevjU6TvzTo1xir1ntTpS9PwpimnPWUTtzutY from "/vercel/path0/plugins/vuex.ts";
import persist_store_4hZmM0uvu4Bw9EoLWIoHN_ZCq_zrV86zwY6iRqgrx7o from "/vercel/path0/plugins/persist-store.ts";
import directives_Y6D8IjviQKB3EteSmgkh6nr8GGru_rC_DjhQ3lLa_GM from "/vercel/path0/plugins/directives.ts";
import event_bus_ViUSoJuKW_GQ8BDIbjhUcCejLK_UaZMaFLZMM_zeOmo from "/vercel/path0/plugins/event-bus.ts";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/vercel/path0/plugins/sentry.client.ts";
import ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  sentry_client_Tkghm6qboZyK83jq3ydlDUVT7O0Cb00E6XcepuVj9_c,
  sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag,
  plugin_client_RuJQXitzGnmqc_cl4mDZoDvC8sTQFiXAObIrP1fN_lk,
  plugin_dZwLP3pabhznAX15ySw1eaAQmwVOMPFRcNoLlER2dWI,
  switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw,
  route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U,
  i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM,
  plugin_7DB4Q_rSg7Y6_r2xTJAR9sj0Plzl7GeeI8C3uqDzeqY,
  axeptio_d5yAA7RxpEzZF2ksZVjyFf4Sc3_ts8XFgqrGcUsQOCo,
  gtm_client_MQaOARgMghyVgOHvZC5M5hQI1nTisUfzlPHTlOKu0TA,
  cookies_sisHiBOjzAv5IPvFgVIiOxXz4TLhr96GevWdTx4s_U4,
  vuex_wQowj8nevjU6TvzTo1xir1ntTpS9PwpimnPWUTtzutY,
  persist_store_4hZmM0uvu4Bw9EoLWIoHN_ZCq_zrV86zwY6iRqgrx7o,
  directives_Y6D8IjviQKB3EteSmgkh6nr8GGru_rC_DjhQ3lLa_GM,
  event_bus_ViUSoJuKW_GQ8BDIbjhUcCejLK_UaZMaFLZMM_zeOmo,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE,
  ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs
]