import { type GetterTree } from 'vuex'
import { RootState } from '../state'
import { type FormState } from './state'

const getters: GetterTree<FormState, RootState> = {
  adresseAutocomplete: (state) => state.adresseAutocomplete,
  addressNumber: (state) => state.addressNumber,
  addressRoad: (state) => state.addressRoad,
  addressDetail: (state) => state.addressDetail,
  addressZipCode: (state) => state.addressZipCode,
  basicHealthCovers: (state) => state.basicHealthCovers,
  child0Birthdate: (state) => state.child0Birthdate,
  child0MandatoryScheme: (state) => state.child0MandatoryScheme,
  child1Birthdate: (state) => state.child1Birthdate,
  child1MandatoryScheme: (state) => state.child1MandatoryScheme,
  child2Birthdate: (state) => state.child2Birthdate,
  child2MandatoryScheme: (state) => state.child2MandatoryScheme,
  child3Birthdate: (state) => state.child3Birthdate,
  child3MandatoryScheme: (state) => state.child3MandatoryScheme,
  child4Birthdate: (state) => state.child4Birthdate,
  child4MandatoryScheme: (state) => state.child4MandatoryScheme,
  child5Birthdate: (state) => state.child5Birthdate,
  child5MandatoryScheme: (state) => state.child5MandatoryScheme,
  childCivilityRefId: (state) => state.childCivilityRefId,
  childFirstName: (state) => state.childFirstName,
  childSurname: (state) => state.childSurname,
  childFrenchSocialSecurityNumber: (state) =>
    state.childFrenchSocialSecurityNumber,
  childAttachmentContributorId: (state) => state.childAttachmentContributorId,
  childHasAttachedSocialSecurity: (state) =>
    state.childHasAttachedSocialSecurity,
  city: (state) => state.city,
  contact: (state) => state.contact,
  currentInsurer: (state) => state.currentInsurer,
  dateEffet: (state) => state.dateEffet,
  dateNaissance: (state) => state.dateNaissance,
  deductibilityMadelinLaw: (state) => state.deductibilityMadelinLaw,
  hasIndividualPolicyInsurance: (state) => state.hasIndividualPolicyInsurance,
  iban: (state) => state.iban,
  isCancelableInsurance: (state) => state.isCancelableInsurance,
  freelancer: (state) => state.freelancer,
  paymentDay: (state) => state.paymentDay,
  peopleComposition: (state) => state.peopleComposition,
  periodicity: (state) => state.periodicity,
  regime: (state) => state.regime,
  spouseBirthdate: (state) => state.spouseBirthdate,
  spouseMandatoryScheme: (state) => state.spouseMandatoryScheme,
  statutPro: (state) => state.statutPro,
  birthName: (state) => state.birthName,
  maritalStatus: (state) => state.maritalStatus,
  nationality: (state) => state.nationality,
  birthPlaceCountry: (state) => state.birthPlaceCountry,
  birthPlaceDepartment: (state) => state.birthPlaceDepartment,
  birthPlaceCityDropdown: (state) => state.birthPlaceCityDropdown,
  birthPlaceCityInput: (state) => state.birthPlaceCityInput,
  socialSecurityNumber: (state) => state.socialSecurityNumber,
  signatureCountryCode: (state) => state.signatureCountryCode,
  signaturePhoneNumber: (state) => state.signaturePhoneNumber,
  spouseCivility: (state) => state.spouse.civility,
  spouseFirstName: (state) => state.spouse.firstName,
  spouseSurname: (state) => state.spouse.surname,
  spouseMaidenName: (state) => state.spouse.maidenName,
  spouseNationality: (state) => state.spouse.nationality,
  spouseSocialSecurityNumber: (state) => state.spouse.socialSecurityNumber,
  spouseBirthPlaceCityDropdown: (state) => state.spouse.birthPlaceCityDropdown,
  spouseBirthPlaceCityInput: (state) => state.spouse.birthPlaceCityInput,
  spouseBirthPlaceCountry: (state) => state.spouse.birthPlaceCountry,
  spouseBirthPlaceDepartment: (state) => state.spouse.birthPlaceDepartment,
  policyNumber: (state) => state.policyNumber,
}

export default getters
