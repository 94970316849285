// Vuelidate validators: see https://vuelidate-next.netlify.app/validators.html

import {
  type ErrorObject,
  type ValidationRuleWithParams,
  type ValidationRuleWithoutParams,
} from '@vuelidate/core'
import {
  helpers,
  required as required$,
  requiredIf as requiredIf$,
  requiredUnless as requiredUnless$,
  maxLength as maxLength$,
  minLength as minLength$,
  sameAs as sameAs$,
  email as email$,
  or,
} from '@vuelidate/validators'
import moment from 'moment'
import { startsWith } from 'lodash' /* eslint-disable-line import/named */

import { dateIsBefore, momentDate } from './date'
import { SepaCountries } from './iban'

// built-in validators with custom message
export const required = (message?: string) =>
  helpers.withMessage(message || 'Ce champs doit être rempli', required$)

export const requiredIf = (condition: () => boolean, message?: string) =>
  helpers.withMessage(
    message || 'Ce champs doit être rempli',
    helpers.withAsync(requiredIf$(condition), condition)
  )

export const requiredUnless = (condition: () => boolean, message?: string) =>
  helpers.withMessage(
    message || 'Ce champs doit être rempli',
    helpers.withAsync(requiredUnless$(condition), condition)
  )

export const maxLength = (max: number, message?: string) =>
  helpers.withMessage(
    ({ $params }) =>
      message || `Ce champs doit contenir moins de ${$params.max} caractères`,
    maxLength$(max)
  )

export const minLength = (min: number, message?: string) =>
  helpers.withMessage(
    ({ $params }) =>
      message || `Ce champs doit contenir au moins ${$params.min} caractères`,
    minLength$(min)
  )

export const minLengthIf = (
  condition: () => boolean,
  min: () => number,
  message: string
) =>
  helpers.withMessage(
    message,
    helpers.withAsync(
      or(
        () => !condition(),
        (value: string | undefined) => (value || '').length >= min()
      ),
      [condition, min]
    )
  )

export const sameAs = (equalTo: any, message?: string) =>
  helpers.withMessage(
    ({ $params }) => message || `Ce champs doit être ${$params.equalTo}`,
    sameAs$(equalTo)
  )

export const email = (message: string) => helpers.withMessage(message, email$)

// custom validators
export const validDate = helpers.withMessage(
  'Pour continuer, renseignez une date valide au format JJ/MM/AAAA.',
  ((value: any) =>
    !helpers.req(value) ||
    momentDate(
      value
    ).isValid()) as unknown as ValidationRuleWithoutParams<string>
)

export const validBirthDate = helpers.withMessage(
  'Pour continuer, renseignez une date de naissance valide.',
  ((value: any) =>
    !helpers.req(value) ||
    !momentDate(value).isValid() ||
    (!momentDate(value).isAfter() &&
      moment().diff(momentDate(value), 'years') <
        112)) as unknown as ValidationRuleWithoutParams<string>
)

export const sepaZoneIban = helpers.withMessage(
  'Pour continuer, renseignez un IBAN de la zone SEPA.',
  (value: string) => {
    const countryCode = value.substring(0, 2)
    return SepaCountries.map((country) => country.code).includes(countryCode)
  }
)

export const ibanLength = helpers.withMessage(
  'L’IBAN doit être valide.',
  (value: string) => {
    const countryCode = value.substring(0, 2)
    return (
      value.length ===
      (SepaCountries.find((country) => country.code === countryCode)?.length ||
        0)
    )
  }
)

export const isValidIban = helpers.withMessage(
  'Pour continuer, renseignez un IBAN valide.',
  helpers.withAsync(async (value: string) => {
    try {
      const cleanedIban = value.replaceAll(' ', '').replaceAll('_', '')

      const response = await fetch(
        `https://openiban.com/validate/${cleanedIban}?getBIC=true&validateBankCode=true`
      )
      const data = await response.json()

      return data.valid
    } catch (error) {
      return false
    }
  })
)

export const isNotSavingAccount = helpers.withMessage(
  'Les comptes épargnes ne sont pas autorisés. Pour continuer, merci d’indiquer un IBAN valide.',
  (value: string) => {
    const cleanedIban = value.replaceAll(' ', '').replaceAll('_', '')
    const bankCode = cleanedIban.substring(4, 9)
    const countryCode = cleanedIban.substring(0, 2)

    return bankCode !== '10011' || countryCode !== 'FR'
  }
)

export const testPhoneCall = (
  condition: () => boolean,
  test: () => Promise<boolean>,
  message?: string
) =>
  helpers.withMessage(
    message || 'Ce champs doit être rempli',
    helpers.withAsync(
      or(() => !condition(), test),
      condition
    )
  )
export const isSelectedInsurerPartOfInsurerList = (
  insurerList: Array<{ value: string }>
) =>
  helpers.withMessage(
    'Pour continuer, sélectionnez une compagnie d’assurance dans la liste proposée.',
    (value: { [key: string]: string }) =>
      !!insurerList.find((insurer) => insurer.value === value?.value)
  )

export const adultBirthDate = (person: string) =>
  helpers.withMessage(
    `${person} doit être majeur. Pour continuer, renseignez une date de naissance valide.`,
    ((value: any) =>
      !momentDate(value).isValid() ||
      momentDate(value).isAfter() ||
      moment().diff(momentDate(value), 'years') >=
        18) as unknown as ValidationRuleWithoutParams<string>
  )

export const childrenBirthDate = helpers.withMessage(
  'Les enfants assurés sur votre contrat doivent avoir moins de 26 ans. Pour continuer, renseignez une date de naissance valide.',
  ((value: any) =>
    !momentDate(value).isValid() ||
    momentDate(value).isAfter() ||
    moment().diff(momentDate(value), 'years') <
      26) as unknown as ValidationRuleWithoutParams<string>
)

export const dateAfter = (
  days: number,
  message?: string | ((days: string) => string)
) =>
  helpers.withMessage(({ $params }) => {
    if (typeof message === 'string') {
      return message
    }

    const date = moment()
      .add($params.days, 'days')
      .toDate()
      .toLocaleDateString('fr')

    if (typeof message === 'function') {
      return message(date)
    }

    return `La date doit être postérieure au ${date}. Pour continuer, renseignez une date valide.`
  }, helpers.withParams({ days }, (value: any) => !helpers.req(value) || !momentDate(value).isValid() || (momentDate(value).isAfter() && moment().diff(momentDate(value), 'days', true) < 1 - days)) as unknown as ValidationRuleWithParams<any>)

export const dateAfterIf = (
  condition: boolean,
  days: number,
  message?: string | ((days: string) => string)
) => condition && dateAfter(days, message)

export const dateBefore = (
  days: number,
  message?: string | ((days: string) => string)
) =>
  helpers.withMessage(
    ({ $params }) => {
      if (typeof message === 'string') {
        return message
      }

      const date = moment()
        .add($params.days, 'days')
        .toDate()
        .toLocaleDateString('fr')

      if (typeof message === 'function') {
        return message(date)
      }

      return `La date doit être antérieure au ${date}. Pour continuer, renseignez une date valide.`
    },
    helpers.withParams({ days }, (value: any) => {
      return (
        !helpers.req(value) ||
        !momentDate(value).isValid() ||
        dateIsBefore(value, days)
      )
    }) as unknown as ValidationRuleWithParams<any>
  )

export const dateBeforeIf = (
  condition: boolean,
  days: number,
  message?: string | ((days: string) => string)
) => condition && dateBefore(days, message)

export const startsWithIf = (
  condition: () => boolean,
  words: string[],
  message: string
) =>
  helpers.withMessage(
    message,
    helpers.withAsync(
      or(
        () => !condition(),
        (value: string) => {
          let result = false

          words.forEach((word) => {
            if (startsWith(value, word)) {
              result = true
            }
          })

          return result
        }
      ),
      condition
    )
  )

// convert errors (typically from v$.attributeName.$errors) to a single string
export const errorMessage = (errors: ErrorObject[]) =>
  (errors || []).map((error) => error.$message).join(' ')

export const regexp = (pattern: string, message: string) =>
  helpers.withMessage(message, helpers.regex(RegExp(pattern)))

export const regexpIf = (
  condition: () => boolean,
  pattern: string,
  message: string
) =>
  helpers.withMessage(
    message,
    helpers.withAsync(
      or(() => !condition(), helpers.regex(RegExp(pattern))),
      condition
    )
  )

const socialSecurityNumberChecksum = (value: string) => {
  const replaced = value.replaceAll('_', '').replace(/ /g, '')

  if (replaced.length !== 15) return true

  const [chain, controlKey] = [replaced.slice(0, 13), replaced.slice(13)]

  return 97 - (Number(chain) % 97) === Number(controlKey)
}

export const socialSecurityNumber = (message: string) =>
  helpers.withMessage(message, socialSecurityNumberChecksum)

export const socialSecurityNumberIf = (
  condition: () => boolean,
  message: string
) =>
  helpers.withMessage(
    message,
    helpers.withAsync(
      or(() => !condition(), socialSecurityNumberChecksum),
      condition
    )
  )
export const testZipCode = (
  condition: () => boolean,
  test: () => Promise<boolean>,
  message?: string
) =>
  helpers.withMessage(
    message || 'Ce champs doit être rempli',
    helpers.withAsync(
      or(() => !condition(), test),
      condition
    )
  )
