import { type ActionTree } from 'vuex'
import { RootState } from '../state'
import { type PrismicState } from './state'

const actions: ActionTree<PrismicState, RootState> = {
  setAdvantagesPush({ commit }, payload: AdvantagesPushPayload) {
    commit('setAdvantagesPush', payload)
  },
  setFooterData({ commit }, payload) {
    commit('setFooterData', payload)
  },
  setMandatorySchemes({ commit }, payload) {
    commit('setMandatorySchemes', payload)
  },
  setOffersContents({ commit }, payload) {
    commit('setOffersContents', payload)
  },
  setOffersLabels({ commit }, payload) {
    commit('setOffersLabels', payload)
  },
  setOffersOptions({ commit }, payload) {
    commit('setOffersOptions', payload)
  },
  setOffersComparatorLabels({ commit }, payload) {
    commit('setOffersComparatorLabels', payload)
  },
  setStepData(
    { commit },
    payload: { key: keyof PrismicState['stepsData']; data: any }
  ) {
    commit('setStepData', payload)
  },
}

export default actions
