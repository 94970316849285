import { type GetterTree } from 'vuex'
import { RootState } from '../state'
import { type TgpState } from './state'

const getters: GetterTree<TgpState, RootState> = {
  adresse: (state) => state.adresse,
  basicHealthCovers: (state) => state.basicHealthCovers,
  beneficiariesCounter: (state) =>
    1 + (state.spouseContributorId ? 1 : 0) + (state.childCounter ?? 0),
  contact: (state) => state.contact,
  counter: (state) => state.tgpCounter,
  child0Birthdate: (state) => state.child0Birthdate,
  child0MandatoryScheme: (state) => state.child0MandatoryScheme,
  child0ContributorId: (state) => state.child0ContributorId,
  child0CivilityRefId: (state) => state.child0CivilityRefId,
  child0FirstName: (state) => state.child0FirstName,
  child0Surname: (state) => state.child0Surname,
  child0FrenchSocialSecurityNumber: (state) =>
    state.child0FrenchSocialSecurityNumber,
  child0AttachmentContributorId: (state) => state.child0AttachmentContributorId,
  child0HasAttachedSocialSecurity: (state) =>
    state.child0HasAttachedSocialSecurity,
  child1Birthdate: (state) => state.child1Birthdate,
  child1MandatoryScheme: (state) => state.child1MandatoryScheme,
  child1ContributorId: (state) => state.child1ContributorId,
  child1CivilityRefId: (state) => state.child1CivilityRefId,
  child1FirstName: (state) => state.child1FirstName,
  child1Surname: (state) => state.child1Surname,
  child1FrenchSocialSecurityNumber: (state) =>
    state.child1FrenchSocialSecurityNumber,
  child1AttachmentContributorId: (state) => state.child1AttachmentContributorId,
  child1HasAttachedSocialSecurity: (state) =>
    state.child1HasAttachedSocialSecurity,
  child2Birthdate: (state) => state.child2Birthdate,
  child2MandatoryScheme: (state) => state.child2MandatoryScheme,
  child2ContributorId: (state) => state.child2ContributorId,
  child2CivilityRefId: (state) => state.child2CivilityRefId,
  child2FirstName: (state) => state.child2FirstName,
  child2Surname: (state) => state.child2Surname,
  child2FrenchSocialSecurityNumber: (state) =>
    state.child2FrenchSocialSecurityNumber,
  child2AttachmentContributorId: (state) => state.child2AttachmentContributorId,
  child2HasAttachedSocialSecurity: (state) =>
    state.child2HasAttachedSocialSecurity,
  child3Birthdate: (state) => state.child3Birthdate,
  child3MandatoryScheme: (state) => state.child3MandatoryScheme,
  child3ContributorId: (state) => state.child3ContributorId,
  child3CivilityRefId: (state) => state.child3CivilityRefId,
  child3FirstName: (state) => state.child3FirstName,
  child3Surname: (state) => state.child3Surname,
  child3FrenchSocialSecurityNumber: (state) =>
    state.child3FrenchSocialSecurityNumber,
  child3AttachmentContributorId: (state) => state.child3AttachmentContributorId,
  child3HasAttachedSocialSecurity: (state) =>
    state.child3HasAttachedSocialSecurity,
  child4Birthdate: (state) => state.child4Birthdate,
  child4MandatoryScheme: (state) => state.child4MandatoryScheme,
  child4ContributorId: (state) => state.child4ContributorId,
  child4CivilityRefId: (state) => state.child4CivilityRefId,
  child4FirstName: (state) => state.child4FirstName,
  child4Surname: (state) => state.child4Surname,
  child4FrenchSocialSecurityNumber: (state) =>
    state.child4FrenchSocialSecurityNumber,
  child4AttachmentContributorId: (state) => state.child4AttachmentContributorId,
  child4HasAttachedSocialSecurity: (state) =>
    state.child4HasAttachedSocialSecurity,
  child5Birthdate: (state) => state.child5Birthdate,
  child5MandatoryScheme: (state) => state.child5MandatoryScheme,
  child5ContributorId: (state) => state.child5ContributorId,
  child5CivilityRefId: (state) => state.child5CivilityRefId,
  child5FirstName: (state) => state.child5FirstName,
  child5Surname: (state) => state.child5Surname,
  child5FrenchSocialSecurityNumber: (state) =>
    state.child5FrenchSocialSecurityNumber,
  child5AttachmentContributorId: (state) => state.child5AttachmentContributorId,
  child5HasAttachedSocialSecurity: (state) =>
    state.child5HasAttachedSocialSecurity,
  childCounter: (state) => state.childCounter,
  commercialOpportunityId: (state) => state.commercialOpportunityId,
  comparatorOffer: (state) => state.comparatorOffer,
  createProjectDate: (state) => state.createProjectDate,
  contributorId: (state) => state.contributorId,
  currentInsurer: (state) => state.currentInsurer,
  etape: (state) => state.etape,
  dateEffet: (state) => state.dateEffet,
  dateChanged: (state) => state.dateChanged,
  dateNaissance: (state) => state.dateNaissance,
  deductibilityMadelinLaw: (state) => state.deductibilityMadelinLaw,
  freelancer: (state) => state.freelancer,
  iban: (state) => state.iban,
  offerId: (state) => state.offerId,
  offerIdPex: (state) => state.offerIdPex,
  orderId: (state) => state.orderId,
  orderIdPex: (state) => state.orderIdPex,
  optionId0: (state) => state.optionId0,
  optionLevel0: (state) => state.optionLevel0,
  optionPrice0: (state) => state.optionPrice0,
  optionId1: (state) => state.optionId1,
  optionLevel1: (state) => state.optionLevel1,
  optionPrice1: (state) => state.optionPrice1,
  peopleComposition: (state) => state.peopleComposition,
  periodicity: (state) => state.periodicity,
  projectId: (state) => state.projectId,
  projectIdPex: (state) => state.projectIdPex,
  projectMarketingId: (state) => state.projectMarketingId,
  projectSituation: (state) => state.projectSituation,
  projectType: (state) => state.projectType,
  regime: (state) => state.regime,
  selectedOffer: (state) => state.selectedOffer,
  spouseBirthdate: (state) => state.spouseBirthdate,
  spouseContributorId: (state) => state.spouseContributorId,
  spouseMandatoryScheme: (state) => state.spouseMandatoryScheme,
  statutPro: (state) => state.statutPro,
  subscription: (state) => state.subscription,
  signature: (state) => state.signature,
  isContactMailSend: (state) => state.isContactMailSend,
}

export default getters
