/* eslint-disable-next-line import/named */
import { isEmpty, isNil, omitBy } from 'lodash'
import moment from 'moment'
import state, { TgpState } from '../store/tgp/state'
import { stringToBoolean } from './form'
import { DATE_FORMAT as STATE_DATE_FORMAT } from './date'

const COOKIE_DATE_FORMAT = 'YYYY-MM-DD'

export const serializeCookie = (
  payload: ProjectSituationPayload | ProjectPayload
) =>
  btoa(
    Object.entries(payload)
      .map(
        ([key, value]: [string, string]) =>
          `${key}:${value ? encodeURI(value) : ''}`
      )
      .join('|')
  )

export const deserializeCookie = (cookieValue: string) =>
  (isEmpty(cookieValue)
    ? {}
    : atob(cookieValue)
        .split('|')
        .map((keyValue: string) => keyValue.split(':'))
        .reduce((acc: object, keyValue: string[]) => {
          let value: string | undefined

          if (keyValue[1]) {
            value = decodeURI(keyValue[1])
          }

          return {
            ...acc,
            ...{ [keyValue[0]]: value },
          }
        }, {})) as {
    [x in ProjectSituationKey | ProjectKey]: string | undefined
  }

export const setValueInCookie = (
  key: ProjectSituationKey | ProjectKey,
  value: string,
  cookieValue: string
) => {
  const payload = {
    ...deserializeCookie(cookieValue),
    ...{ [key]: value },
  }

  return serializeCookie(omitBy(payload, isNil))
}

export const formatDateToStateFormat = (date: string) => {
  return moment(date, COOKIE_DATE_FORMAT).format(STATE_DATE_FORMAT)
}

export const formatDateFromStateFormat = (date: string) => {
  return moment(date, STATE_DATE_FORMAT).format(COOKIE_DATE_FORMAT)
}

const parseCoverLevel = (value: string | undefined) => {
  if (!value) return '1'

  try {
    return parseInt(value)
  } catch (error) {
    return '1'
  }
}

export const cookieValuetoTgpState = (cookieValue: string) => {
  if (isEmpty(cookieValue)) return state()

  const payload = deserializeCookie(cookieValue)

  return {
    ...state(),
    adresse: {
      addressNumber: payload.addressNumber,
      addressRoad: payload.addressRoad,
      addressDetail: payload.addressDetail,
      addressZipCode: payload.addressZipCode,
      city: payload.city,
    },
    basicHealthCovers: {
      hospitalization: parseCoverLevel(payload.hospitalization),
      medicalExpenses: parseCoverLevel(payload.medicalExpenses),
      optical: parseCoverLevel(payload.optical),
      dental: parseCoverLevel(payload.dental),
      hearingAid: stringToBoolean(payload.hearingAid),
      naturalMedecine: stringToBoolean(payload.naturalMedecine),
    },
    child0Birthdate: payload.birthDateChild0,
    child0MandatoryScheme: payload.mandatorySchemeRefIdChild0,
    child1Birthdate: payload.birthDateChild1,
    child1MandatoryScheme: payload.mandatorySchemeRefIdChild1,
    child2Birthdate: payload.birthDateChild2,
    child2MandatoryScheme: payload.mandatorySchemeRefIdChild2,
    child3Birthdate: payload.birthDateChild3,
    child3MandatoryScheme: payload.mandatorySchemeRefIdChild3,
    child4Birthdate: payload.birthDateChild4,
    child4MandatoryScheme: payload.mandatorySchemeRefIdChild4,
    child5Birthdate: payload.birthDateChild5,
    child5MandatoryScheme: payload.mandatorySchemeRefIdChild5,
    createProjectDate: payload.createProjectDate,
    dateEffet:
      payload.effectiveDate && formatDateToStateFormat(payload.effectiveDate),
    dateNaissance:
      payload.birthDate && formatDateToStateFormat(payload.birthDate),
    optionId0: payload.optionId0,
    optionLevel0: payload.optionLevel0,
    optionId1: payload.optionId1,
    optionLevel1: payload.optionLevel1,
    peopleComposition: payload.peopleComposition,
    projectSituation: {
      hasIndividualPolicyInsurance: payload.hasIndividualPolicyInsurance,
      isCancelableInsurance: payload.isCancelableInsurance,
    },
    regime: payload.mandatorySchemeRefId,
    statutPro: payload.professionalStatusRefId,
    spouseBirthdate:
      payload.birthDateSpouse &&
      formatDateToStateFormat(payload.birthDateSpouse),
    spouseMandatoryScheme: payload.mandatorySchemeRefIdSpouse,
  } as TgpState
}
