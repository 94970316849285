import {
  CountryCode,
  type Address,
  type Contact,
  type DropdownOption,
} from '~/types/global'
import { type BasicHealthCoversTgp } from '~/types/basic-health-covers'
import {
  SubscriptionPayload,
  SubscriptionSpousePayload,
} from '~~/types/souscription'
import { type Offer } from '~/types/offers'

export type TgpState = {
  tgpCounter: number
  adresse?: Address
  basicHealthCovers?: BasicHealthCoversTgp
  child0Birthdate?: string
  child0MandatoryScheme?: string
  child0ContributorId?: string
  child0CivilityRefId?: string
  child0FirstName?: string
  child0Surname?: string
  child0FrenchSocialSecurityNumber?: string
  child0AttachmentContributorId?: string
  child0HasAttachedSocialSecurity?: 'true' | 'false'
  child1Birthdate?: string
  child1MandatoryScheme?: string
  child1ContributorId?: string
  child1CivilityRefId?: string
  child1FirstName?: string
  child1Surname?: string
  child1FrenchSocialSecurityNumber?: string
  child1AttachmentContributorId?: string
  child1HasAttachedSocialSecurity?: 'true' | 'false'
  child2Birthdate?: string
  child2MandatoryScheme?: string
  child2ContributorId?: string
  child2CivilityRefId?: string
  child2FirstName?: string
  child2Surname?: string
  child2FrenchSocialSecurityNumber?: string
  child2AttachmentContributorId?: string
  child2HasAttachedSocialSecurity?: 'true' | 'false'
  child3Birthdate?: string
  child3MandatoryScheme?: string
  child3ContributorId?: string
  child3CivilityRefId?: string
  child3FirstName?: string
  child3Surname?: string
  child3FrenchSocialSecurityNumber?: string
  child3AttachmentContributorId?: string
  child3HasAttachedSocialSecurity?: 'true' | 'false'
  child4Birthdate?: string
  child4MandatoryScheme?: string
  child4ContributorId?: string
  child4CivilityRefId?: string
  child4FirstName?: string
  child4Surname?: string
  child4FrenchSocialSecurityNumber?: string
  child4AttachmentContributorId?: string
  child4HasAttachedSocialSecurity?: 'true' | 'false'
  child5Birthdate?: string
  child5MandatoryScheme?: string
  child5ContributorId?: string
  child5CivilityRefId?: string
  child5FirstName?: string
  child5Surname?: string
  child5FrenchSocialSecurityNumber?: string
  child5AttachmentContributorId?: string
  child5HasAttachedSocialSecurity?: 'true' | 'false'
  childCounter: number
  commercialOpportunityId?: string
  comparatorOffer?: Offer
  contact?: Contact
  contributorId?: string
  createProjectDate?: string
  currentInsurer?: DropdownOption
  etape?: string
  dateChanged?: boolean
  dateEffet?: string
  dateNaissance?: string
  deductibilityMadelinLaw?: 'true' | 'false'
  freelancer?: 'true' | 'false'
  hasCreatedNeeds: boolean
  iban?: string
  offerId?: string
  offerIdPex?: string
  orderId?: string
  orderIdPex?: string
  optionId0?: string
  optionLabel0?: string
  optionLevel0?: string
  optionPrice0: number
  optionId1?: string
  optionLabel1?: string
  optionLevel1?: string
  optionPrice1: number
  paymentDay?: string
  peopleComposition?: string
  policyNumber?: string
  periodicity?: string
  projectId?: string
  projectIdPex?: string
  projectMarketingId?: string
  projectSituation?: {
    hasIndividualPolicyInsurance?: 'true' | 'false'
    isCancelableInsurance?: 'true' | 'false'
  }
  projectType?: string
  regime?: string
  selectedOffer?: Offer
  spouseBirthdate?: string
  spouseContributorId?: string
  spouseMandatoryScheme?: string
  statutPro?: string
  subscription?: SubscriptionPayload
  signature?: {
    countryCode: CountryCode
    phoneNumber: string
  }
  spouse?: SubscriptionSpousePayload
  isContactMailSend?: Boolean
}
const state = (): TgpState => ({
  tgpCounter: 0,
  adresse: undefined,
  basicHealthCovers: undefined,
  child0Birthdate: undefined,
  child0MandatoryScheme: undefined,
  child0ContributorId: undefined,
  child0CivilityRefId: undefined,
  child0FirstName: undefined,
  child0Surname: undefined,
  child0FrenchSocialSecurityNumber: undefined,
  child0AttachmentContributorId: undefined,
  child0HasAttachedSocialSecurity: undefined,
  child1Birthdate: undefined,
  child1MandatoryScheme: undefined,
  child1ContributorId: undefined,
  child1CivilityRefId: undefined,
  child1FirstName: undefined,
  child1Surname: undefined,
  child1FrenchSocialSecurityNumber: undefined,
  child1AttachmentContributorId: undefined,
  child1HasAttachedSocialSecurity: undefined,
  child2Birthdate: undefined,
  child2MandatoryScheme: undefined,
  child2ContributorId: undefined,
  child2CivilityRefId: undefined,
  child2FirstName: undefined,
  child2Surname: undefined,
  child2FrenchSocialSecurityNumber: undefined,
  child2AttachmentContributorId: undefined,
  child2HasAttachedSocialSecurity: undefined,
  child3Birthdate: undefined,
  child3MandatoryScheme: undefined,
  child3ContributorId: undefined,
  child3CivilityRefId: undefined,
  child3FirstName: undefined,
  child3Surname: undefined,
  child3FrenchSocialSecurityNumber: undefined,
  child3AttachmentContributorId: undefined,
  child3HasAttachedSocialSecurity: undefined,
  child4Birthdate: undefined,
  child4MandatoryScheme: undefined,
  child4ContributorId: undefined,
  child4CivilityRefId: undefined,
  child4FirstName: undefined,
  child4Surname: undefined,
  child4FrenchSocialSecurityNumber: undefined,
  child4AttachmentContributorId: undefined,
  child4HasAttachedSocialSecurity: undefined,
  child5Birthdate: undefined,
  child5MandatoryScheme: undefined,
  child5ContributorId: undefined,
  child5CivilityRefId: undefined,
  child5FirstName: undefined,
  child5Surname: undefined,
  child5FrenchSocialSecurityNumber: undefined,
  child5AttachmentContributorId: undefined,
  child5HasAttachedSocialSecurity: undefined,
  childCounter: 0,
  commercialOpportunityId: undefined,
  comparatorOffer: undefined,
  contact: undefined,
  createProjectDate: undefined,
  contributorId: undefined,
  currentInsurer: undefined,
  etape: undefined,
  dateChanged: false,
  dateEffet: undefined,
  dateNaissance: undefined,
  deductibilityMadelinLaw: undefined,
  freelancer: undefined,
  hasCreatedNeeds: false,
  iban: undefined,
  offerId: undefined,
  offerIdPex: undefined,
  orderId: undefined,
  orderIdPex: undefined,
  optionId0: undefined,
  optionLabel0: undefined,
  optionLevel0: undefined,
  optionPrice0: 0,
  optionId1: undefined,
  optionLabel1: undefined,
  optionLevel1: undefined,
  optionPrice1: 0,
  peopleComposition: undefined,
  paymentDay: undefined,
  periodicity: undefined,
  projectId: undefined,
  projectIdPex: undefined,
  projectMarketingId: undefined,
  projectSituation: undefined,
  projectType: undefined,
  regime: undefined,
  selectedOffer: undefined,
  signature: undefined,
  spouse: undefined,
  spouseBirthdate: undefined,
  spouseContributorId: undefined,
  spouseMandatoryScheme: undefined,
  statutPro: undefined,
  subscription: undefined,
  isContactMailSend: false,
})

export default state
