import { RichText } from 'prismic-dom'

const REGEX_DATE = /\[J\+(\d+)\]/

const decodeHTML = (str: string) => {
  const symbols: any = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&apos;': "'",
  }

  let newStr = str

  for (const symbol in symbols) {
    if (str.includes(symbol)) {
      newStr = newStr.replaceAll(symbol, symbols[symbol])
    }
  }

  return newStr
}

export function htmlSerializer(
  type: string,
  _element: any,
  _text: any,
  children: string[]
) {
  if (type === 'heading1') return `<h1>${children.map(decodeHTML).join()}</h1>`
  return null
}

export function extractStartDate(text: string) {
  if (!text) {
    return null
  }

  const result = text.match(REGEX_DATE)

  if (result) {
    const daysMore = parseInt(result[1])
    const date = new Date()

    date.setDate(date.getDate() + daysMore)

    return date
  }

  return null
}

export function dateSerializer(
  _type: string,
  _element: any,
  text: string,
  children: string[]
) {
  if (!text || children.length) {
    return null
  }

  const date = extractStartDate(text)

  if (date) {
    return text.replace(REGEX_DATE, date.toLocaleDateString('fr'))
  }

  return null
}

export const formatAdvantagesPushComponentPayload = (
  slicePayload: PrismicAdvantagesPushSlice,
  modulePayload: PrismicAdvantagesPushModule
): AdvantagesPushPayload => ({
  title: RichText.asHtml(modulePayload.advantage_title),
  showTitle: slicePayload?.primary?.show_title || false,
  displayLeft: slicePayload?.primary?.display_left || false,
  listItems: modulePayload.advantage_entries.map(
    (modulePayload: {
      avantage_entry_icon: { cdn_link: any }
      advantage_entry_title: any
      advantage_entry_description: any
    }) => ({
      icon: {
        filename: modulePayload.avantage_entry_icon?.cdn_link,
        alt: 'test',
      },
      title: modulePayload.advantage_entry_title,
      text: RichText.asHtml(modulePayload.advantage_entry_description),
    })
  ),
})

export function injectPhoneNumber(
  text: string,
  phoneNumber: string,
  withLink?: boolean
) {
  if (phoneNumber.startsWith('+33')) {
    phoneNumber = phoneNumber.replace('+33', '0')
  }

  phoneNumber = phoneNumber.replace('*', '')

  const formattedPhoneNumber = phoneNumber.match(/\d{1,2}/g)?.join('&nbsp;')

  if (formattedPhoneNumber) {
    if (withLink) {
      return text.replace(
        '{{ phoneNumber }}',
        `<a href='tel:${phoneNumber
          .split(' ')
          .join('')}'>${formattedPhoneNumber}</a>`
      )
    } else {
      return text.replace('{{ phoneNumber }}', formattedPhoneNumber)
    }
  } else {
    return text.replace('{{ phoneNumber }}', '')
  }
}

export function injectDate(text: string, date: string) {
  if (date !== '') {
    return text.replace('{{ date }}', date)
  } else {
    return text.replace('{{ date }}', '')
  }
}

export function injectEmail(text: string, email: string) {
  if (email !== '') {
    return text.replace('{{ email }}', email)
  } else {
    return text.replace('{{ email }}', '')
  }
}

export function injectAmount(text: string, amount: string) {
  if (amount !== '') {
    return text.replace('{{ amount }}', amount)
  } else {
    return text.replace('{{ amount }}', '')
  }
}

export function removeHTMLTags(str: string) {
  if (str === null || str === '') {
    return null
  } else {
    str = str.toString()
  }

  return str.replace(/(<([^>]+)>)/gi, '')
}
