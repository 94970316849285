import { type MutationTree } from 'vuex'
import { type Step, type StepSection, type StepState } from './state'

const mutations: MutationTree<StepState> = {
  setCurrentSection: (state, value: StepSection) =>
    (state.currentSection = value),
  setCurrentStep: (state, value: Step) => (state.currentStep = value),
}

export default mutations
