import { type GetterTree } from 'vuex'
import { RootState } from '../state'
import { type PrismicState } from './state'

const getters: GetterTree<PrismicState, RootState> = {
  advantagesPush: (state) => state.advantagesPush,
  footerData: (state) => state.footerData,
  mandatorySchemes: (state) => state.mandatorySchemes,
  offersContents: (state) => state.offers.contents,
  offersLabels: (state) => state.offers.labels,
  offersOptions: (state) => state.offers.options,
  offersComparatorLabels: (state) => state.offers.comparatorLabels,
  stepsData: (state) => state.stepsData,
}

export default getters
