import { type MutationTree } from 'vuex'
import { type TgpState } from './state'
import { type Address, type Contact, type DropdownOption } from '~/types/global'
import {
  SubscriptionPayload,
  SubscriptionSpousePayload,
} from '~~/types/souscription'
import { type Offer } from '~/types/offers'

const mutations: MutationTree<TgpState> = {
  setAdresse: (state: TgpState, value: Address) => (state.adresse = value),
  setBasicHealthCovers: (
    state: TgpState,
    value: TgpState['basicHealthCovers']
  ) =>
    (state.basicHealthCovers = {
      ...state.basicHealthCovers,
      ...value,
    } as TgpState['basicHealthCovers']),
  setChild0Birthdate: (state: TgpState, value: string) =>
    (state.child0Birthdate = value),
  setChild0MandatoryScheme: (state: TgpState, value: string) =>
    (state.child0MandatoryScheme = value),
  setChild0ContributorId: (state: TgpState, value: string) =>
    (state.child0ContributorId = value),
  setChild0CivilityRefId: (state: TgpState, value: string) =>
    (state.child0CivilityRefId = value),
  setChild0FirstName: (state: TgpState, value: string) =>
    (state.child0FirstName = value),
  setChild0Surname: (state: TgpState, value: string) =>
    (state.child0Surname = value),
  setChild0FrenchSocialSecurityNumber: (state: TgpState, value: string) =>
    (state.child0FrenchSocialSecurityNumber = value),
  setChild0AttachmentContributorId: (state: TgpState, value: string) =>
    (state.child0AttachmentContributorId = value),
  setChild0HasAttachedSocialSecurity: (
    state: TgpState,
    value: 'true' | 'false'
  ) => (state.child0HasAttachedSocialSecurity = value),
  setChild1Birthdate: (state: TgpState, value: string) =>
    (state.child1Birthdate = value),
  setChild1MandatoryScheme: (state: TgpState, value: string) =>
    (state.child1MandatoryScheme = value),
  setChild1ContributorId: (state: TgpState, value: string) =>
    (state.child1ContributorId = value),
  setChild1CivilityRefId: (state: TgpState, value: string) =>
    (state.child1CivilityRefId = value),
  setChild1FirstName: (state: TgpState, value: string) =>
    (state.child1FirstName = value),
  setChild1Surname: (state: TgpState, value: string) =>
    (state.child1Surname = value),
  setChild1FrenchSocialSecurityNumber: (state: TgpState, value: string) =>
    (state.child1FrenchSocialSecurityNumber = value),
  setChild1AttachmentContributorId: (state: TgpState, value: string) =>
    (state.child1AttachmentContributorId = value),
  setChild1HasAttachedSocialSecurity: (
    state: TgpState,
    value: 'true' | 'false'
  ) => (state.child1HasAttachedSocialSecurity = value),
  setChild2Birthdate: (state: TgpState, value: string) =>
    (state.child2Birthdate = value),
  setChild2MandatoryScheme: (state: TgpState, value: string) =>
    (state.child2MandatoryScheme = value),
  setChild2ContributorId: (state: TgpState, value: string) =>
    (state.child2ContributorId = value),
  setChild2CivilityRefId: (state: TgpState, value: string) =>
    (state.child2CivilityRefId = value),
  setChild2FirstName: (state: TgpState, value: string) =>
    (state.child2FirstName = value),
  setChild2Surname: (state: TgpState, value: string) =>
    (state.child2Surname = value),
  setChild2FrenchSocialSecurityNumber: (state: TgpState, value: string) =>
    (state.child2FrenchSocialSecurityNumber = value),
  setChild2AttachmentContributorId: (state: TgpState, value: string) =>
    (state.child2AttachmentContributorId = value),
  setChild2HasAttachedSocialSecurity: (
    state: TgpState,
    value: 'true' | 'false'
  ) => (state.child2HasAttachedSocialSecurity = value),
  setChild3Birthdate: (state: TgpState, value: string) =>
    (state.child3Birthdate = value),
  setChild3MandatoryScheme: (state: TgpState, value: string) =>
    (state.child3MandatoryScheme = value),
  setChild3ContributorId: (state: TgpState, value: string) =>
    (state.child3ContributorId = value),
  setChild3CivilityRefId: (state: TgpState, value: string) =>
    (state.child3CivilityRefId = value),
  setChild3FirstName: (state: TgpState, value: string) =>
    (state.child3FirstName = value),
  setChild3Surname: (state: TgpState, value: string) =>
    (state.child3Surname = value),
  setChild3FrenchSocialSecurityNumber: (state: TgpState, value: string) =>
    (state.child3FrenchSocialSecurityNumber = value),
  setChild3AttachmentContributorId: (state: TgpState, value: string) =>
    (state.child3AttachmentContributorId = value),
  setChild3HasAttachedSocialSecurity: (
    state: TgpState,
    value: 'true' | 'false'
  ) => (state.child3HasAttachedSocialSecurity = value),
  setChild4Birthdate: (state: TgpState, value: string) =>
    (state.child4Birthdate = value),
  setChild4MandatoryScheme: (state: TgpState, value: string) =>
    (state.child4MandatoryScheme = value),
  setChild4ContributorId: (state: TgpState, value: string) =>
    (state.child4ContributorId = value),
  setChild4CivilityRefId: (state: TgpState, value: string) =>
    (state.child4CivilityRefId = value),
  setChild4FirstName: (state: TgpState, value: string) =>
    (state.child4FirstName = value),
  setChild4Surname: (state: TgpState, value: string) =>
    (state.child4Surname = value),
  setChild4FrenchSocialSecurityNumber: (state: TgpState, value: string) =>
    (state.child4FrenchSocialSecurityNumber = value),
  setChild4AttachmentContributorId: (state: TgpState, value: string) =>
    (state.child4AttachmentContributorId = value),
  setChild4HasAttachedSocialSecurity: (
    state: TgpState,
    value: 'true' | 'false'
  ) => (state.child4HasAttachedSocialSecurity = value),
  setChild5Birthdate: (state: TgpState, value: string) =>
    (state.child5Birthdate = value),
  setChild5MandatoryScheme: (state: TgpState, value: string) =>
    (state.child5MandatoryScheme = value),
  setChild5ContributorId: (state: TgpState, value: string) =>
    (state.child5ContributorId = value),
  setChild5CivilityRefId: (state: TgpState, value: string) =>
    (state.child5CivilityRefId = value),
  setChild5FirstName: (state: TgpState, value: string) =>
    (state.child5FirstName = value),
  setChild5Surname: (state: TgpState, value: string) =>
    (state.child5Surname = value),
  setChild5FrenchSocialSecurityNumber: (state: TgpState, value: string) =>
    (state.child5FrenchSocialSecurityNumber = value),
  setChild5AttachmentContributorId: (state: TgpState, value: string) =>
    (state.child5AttachmentContributorId = value),
  setChild5HasAttachedSocialSecurity: (
    state: TgpState,
    value: 'true' | 'false'
  ) => (state.child5HasAttachedSocialSecurity = value),
  setChildCounter: (state: TgpState, value: number) =>
    (state.childCounter = value),
  setContact: (state: TgpState, value: Contact) => (state.contact = value),
  setCounter: (state: TgpState, value: number) => (state.tgpCounter = value),
  setCommercialOpportunityId: (state: TgpState, value: string) =>
    (state.commercialOpportunityId = value),
  setComparatorOffer: (state: TgpState, value: Offer) =>
    (state.comparatorOffer = value),
  setCreateProjectDate: (state: TgpState, value: string) =>
    (state.createProjectDate = value),
  setContributorId: (state: TgpState, value: string) =>
    (state.contributorId = value),
  setCurrentInsurer: (state: TgpState, value: DropdownOption) =>
    (state.currentInsurer = value),
  setEtape: (state: TgpState, value: string) => (state.etape = value),
  setDateChanged: (state: TgpState, value: boolean) =>
    (state.dateChanged = value),
  setDateEffet: (state: TgpState, value: string) => (state.dateEffet = value),
  setDateNaissance: (state: TgpState, value: string) =>
    (state.dateNaissance = value),
  setDeductibilityMadelinLaw: (state: TgpState, value: 'true' | 'false') =>
    (state.deductibilityMadelinLaw = value),
  setFreelancer: (state: TgpState, value: 'true' | 'false') =>
    (state.freelancer = value),
  setHasCreatedNeeds: (state: TgpState, value: boolean) =>
    (state.hasCreatedNeeds = value),
  setIban: (state: TgpState, value: string) => (state.iban = value),
  setRegime: (state: TgpState, value: string) => (state.regime = value),
  setStatutPro: (state: TgpState, value: string) => (state.statutPro = value),
  setOfferId: (state: TgpState, value: string) => (state.offerId = value),
  setOfferIdPex: (state: TgpState, value: string) => (state.offerIdPex = value),
  setOrderId: (state: TgpState, value: string) => (state.orderId = value),
  setOrderIdPex: (state: TgpState, value: string) => (state.orderIdPex = value),
  setOptionId0: (state: TgpState, value: string) => (state.optionId0 = value),
  setOptionLabel0: (state: TgpState, value: string) =>
    (state.optionLabel0 = value),
  setOptionLevel0: (state: TgpState, value: string) =>
    (state.optionLevel0 = value),
  setOptionPrice0: (state: TgpState, value: number) =>
    (state.optionPrice0 = value),
  setOptionId1: (state: TgpState, value: string) => (state.optionId1 = value),
  setOptionLabel1: (state: TgpState, value: string) =>
    (state.optionLabel1 = value),
  setOptionLevel1: (state: TgpState, value: string) =>
    (state.optionLevel1 = value),
  setOptionPrice1: (state: TgpState, value: number) =>
    (state.optionPrice1 = value),
  setPaymentDay: (state: TgpState, value: string) => (state.paymentDay = value),
  setPeopleComposition: (state: TgpState, value: string) =>
    (state.peopleComposition = value),
  setPolicyNumber: (state: TgpState, value: string) =>
    (state.policyNumber = value),
  setPeriodicity: (state: TgpState, value: string) =>
    (state.periodicity = value),
  setProjectId: (state: TgpState, value: string) => (state.projectId = value),
  setProjectIdPex: (state: TgpState, value: string) =>
    (state.projectIdPex = value),
  setProjectMarketingId: (state: TgpState, value: string) =>
    (state.projectMarketingId = value),
  setProjectSituation: (state: TgpState, value: TgpState['projectSituation']) =>
    (state.projectSituation = { ...state.projectSituation, ...value }),
  setSelectedOffer: (state: TgpState, offer: Offer) =>
    (state.selectedOffer = offer),
  setSpouseBirthdate: (state: TgpState, value: string) =>
    (state.spouseBirthdate = value),
  setSpouseContributorId: (state: TgpState, value: string) =>
    (state.spouseContributorId = value),
  setSpouseMandatoryScheme: (state: TgpState, value: string) =>
    (state.spouseMandatoryScheme = value),
  setProjectType: (state: TgpState, value: string) =>
    (state.projectType = value),
  setSubscription: (state: TgpState, value: SubscriptionPayload) => {
    state.subscription = value
  },
  setSignature: (state: TgpState, value: TgpState['signature']) =>
    (state.signature = value),
  setSpouseSubscription: (
    state: TgpState,
    value: SubscriptionSpousePayload
  ) => {
    state.spouse = value
  },
  setIsContactMailSend: (state: TgpState, value: Boolean) => {
    state.isContactMailSend = value
  },
}

export default mutations
