import { type MutationTree } from 'vuex'
import { type FormState } from './state'
import { CountryCode, DropdownOption } from '~~/types/global'

const mutations: MutationTree<FormState> = {
  setAdresseAutocomplete: (state, value: string) =>
    (state.adresseAutocomplete = value),
  setAddressNumber: (state, value: string) => (state.addressNumber = value),
  setAddressRoad: (state, value: string) => (state.addressRoad = value),
  setAddressDetail: (state, value: string) => (state.addressDetail = value),
  setAddressZipCode: (state, value: string) => (state.addressZipCode = value),
  setBasicHealthCovers: (state, value: FormState['basicHealthCovers']) =>
    (state.basicHealthCovers = { ...state.basicHealthCovers, ...value }),
  setChild0Birthdate: (state, value: string) => (state.child0Birthdate = value),
  setChild0MandatoryScheme: (state, value: string) =>
    (state.child0MandatoryScheme = value),
  setChild1Birthdate: (state, value: string) => (state.child1Birthdate = value),
  setChild1MandatoryScheme: (state, value: string) =>
    (state.child1MandatoryScheme = value),
  setChild2Birthdate: (state, value: string) => (state.child2Birthdate = value),
  setChild2MandatoryScheme: (state, value: string) =>
    (state.child2MandatoryScheme = value),
  setChild3Birthdate: (state, value: string) => (state.child3Birthdate = value),
  setChild3MandatoryScheme: (state, value: string) =>
    (state.child3MandatoryScheme = value),
  setChild4Birthdate: (state, value: string) => (state.child4Birthdate = value),
  setChild4MandatoryScheme: (state, value: string) =>
    (state.child4MandatoryScheme = value),
  setChild5Birthdate: (state, value: string) => (state.child5Birthdate = value),
  setChild5MandatoryScheme: (state, value: string) =>
    (state.child5MandatoryScheme = value),
  setChildCivilityRefId: (state, value: string) => {
    state.children[state.currentChild] = {
      ...(state.children[state.currentChild] || {}),
      civilityRefId: value,
    }

    return (state.childCivilityRefId = value)
  },
  setChildFirstName: (state, value: string) => {
    state.children[state.currentChild] = {
      ...(state.children[state.currentChild] || {}),
      firstName: value,
    }

    return (state.childFirstName = value)
  },
  setChildSurname: (state, value: string) => {
    state.children[state.currentChild] = {
      ...(state.children[state.currentChild] || {}),
      surname: value,
    }

    return (state.childSurname = value)
  },
  setChildFrenchSocialSecurityNumber: (state, value: string) => {
    state.children[state.currentChild] = {
      ...(state.children[state.currentChild] || {}),
      frenchSocialSecurityNumber: value,
    }

    return (state.childFrenchSocialSecurityNumber = value)
  },
  setChildAttachmentContributorId: (state, value: string) => {
    state.children[state.currentChild] = {
      ...(state.children[state.currentChild] || {}),
      attachmentContributorId: value,
    }

    return (state.childAttachmentContributorId = value)
  },
  setChildHasAttachedSocialSecurity: (state, value: boolean) => {
    state.children[state.currentChild] = {
      ...(state.children[state.currentChild] || {}),
      hasAttachedSocialSecurity: value,
    }

    return (state.childHasAttachedSocialSecurity = value)
  },
  setCity: (state, value: string) => (state.city = value),
  setContact: (state, payload: object) =>
    (state.contact = { ...state.contact, ...payload }),
  setCurrentChild: (state, value: number) => (state.currentChild = value),
  setCurrentInsurer: (state, value: DropdownOption) =>
    (state.currentInsurer = value),
  setDateEffet: (state, value: string) => (state.dateEffet = value),
  setDateNaissance: (state, value: string) => (state.dateNaissance = value),
  setDeductibilityMadelinLaw: (state, value: boolean) =>
    (state.deductibilityMadelinLaw = value),
  setHasIndividualPolicyInsurance: (state, value: boolean) =>
    (state.hasIndividualPolicyInsurance = value),
  setIsCancelableInsurance: (state, value: boolean) =>
    (state.isCancelableInsurance = value),
  setIban: (state, value: string) => (state.iban = value),
  setFreelancer: (state, value: boolean) => (state.freelancer = value),
  setPaymentDay: (state, value: DropdownOption) => (state.paymentDay = value),
  setPeopleComposition: (state, value: string) =>
    (state.peopleComposition = value),
  setPeriodicity: (state, value: DropdownOption) => (state.periodicity = value),
  setPolicyNumber: (state, value: string) => (state.policyNumber = value),
  setRegime: (state, value: string) => (state.regime = value),
  setSpouseBirthdate: (state, value: string) => (state.spouseBirthdate = value),
  setSpouseMandatoryScheme: (state, value: string) =>
    (state.spouseMandatoryScheme = value),
  setStatutPro: (state, value: string) => (state.statutPro = value),
  setBirthName: (state, value: string) => (state.birthName = value),
  setMaritalStatus: (state, value: DropdownOption) =>
    (state.maritalStatus = value),
  setNationality: (state, value: DropdownOption) => (state.nationality = value),
  setBirthPlaceCountry: (state, value: DropdownOption) =>
    (state.birthPlaceCountry = value),
  setBirthPlaceDepartment: (state, value: DropdownOption) =>
    (state.birthPlaceDepartment = value),
  setBirthPlaceCityDropdown: (state, value: DropdownOption) =>
    (state.birthPlaceCityDropdown = value),
  setBirthPlaceCityInput: (state, value: string) =>
    (state.birthPlaceCityInput = value),
  setSocialSecurityNumber: (state, value: string) =>
    (state.socialSecurityNumber = value),
  setSignatureCountryCode: (state, payload: CountryCode) =>
    (state.signatureCountryCode = payload),
  setSignaturePhoneNumber: (state, value: string) =>
    (state.signaturePhoneNumber = value),
  setSpouseCivility: (state, value: string) => (state.spouse.civility = value),
  setSpouseFirstName: (state, value: string) =>
    (state.spouse.firstName = value),
  setSpouseSurname: (state, value: string) => (state.spouse.surname = value),
  setSpouseMaidenName: (state, value: string) =>
    (state.spouse.maidenName = value),
  setSpouseSocialSecurityNumber: (state, value: string) =>
    (state.spouse.socialSecurityNumber = value),
  setSpouseNationality: (state, value: DropdownOption) =>
    (state.spouse.nationality = value),
  setSpouseBirthPlaceCityDropdown: (state, value: DropdownOption) =>
    (state.spouse.birthPlaceCityDropdown = value),
  setSpouseBirthPlaceCityInput: (state, value: string) =>
    (state.spouse.birthPlaceCityInput = value),
  setSpouseBirthPlaceCountry: (state, value: DropdownOption) =>
    (state.spouse.birthPlaceCountry = value),
  setSpouseBirthPlaceDepartment: (state, value: DropdownOption) =>
    (state.spouse.birthPlaceDepartment = value),
}

export default mutations
