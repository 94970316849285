export const SepaCountries = [
  { name: 'Allemagne', code: 'DE', length: 22 },
  { name: 'Autriche', code: 'AT', length: 20 },
  { name: 'Belgique', code: 'BE', length: 16 },
  { name: 'Bulgarie', code: 'BG', length: 22 },
  { name: 'Chypre', code: 'CY', length: 28 },
  { name: 'Croatie', code: 'HR', length: 21 },
  { name: 'Danemark', code: 'DK', length: 18 },
  { name: 'Espagne', code: 'ES', length: 24 },
  { name: 'Estonie', code: 'EE', length: 20 },
  { name: 'Finlande', code: 'FI', length: 18 },
  { name: 'France', code: 'FR', length: 27 },
  { name: 'Gibraltar', code: 'GI', length: 23 },
  { name: 'Grèce', code: 'GR', length: 27 },
  { name: 'Groenland', code: 'GL', length: 18 },
  { name: 'Hongrie', code: 'HU', length: 28 },
  { name: 'Ile Feroé', code: 'FO', length: 18 },
  { name: 'Irelande', code: 'IE', length: 22 },
  { name: 'Islande', code: 'IS', length: 26 },
  { name: 'Italie', code: 'IT', length: 27 },
  { name: 'Lettonie', code: 'LV', length: 21 },
  { name: 'Liechtenstein', code: 'LI', length: 21 },
  { name: 'Lithuanie', code: 'LT', length: 20 },
  { name: 'Luxembourg', code: 'LU', length: 20 },
  { name: 'Malte', code: 'MT', length: 31 },
  { name: 'Monaco', code: 'MC', length: 27 },
  { name: 'Norvège', code: 'NO', length: 15 },
  { name: 'Pays Bas', code: 'NL', length: 18 },
  { name: 'Pologne', code: 'PL', length: 28 },
  { name: 'Portugal', code: 'PT', length: 25 },
  { name: 'République Tchèque', code: 'CZ', length: 24 },
  { name: 'Roumanie', code: 'RO', length: 24 },
  { name: 'Royaume Uni', code: 'GB', length: 22 },
  { name: 'Saint Marin', code: 'SM', length: 27 },
  { name: 'Slovaquie', code: 'SK', length: 24 },
  { name: 'Slovénie', code: 'SI', length: 19 },
  { name: 'Suède', code: 'SE', length: 24 },
  { name: 'Suisse', code: 'CH', length: 21 },
]

export const ibanMask = {
  mask: [
    { mask: '** ** **** **** **** **** **', startsWith: 'DE' },
    { mask: '** ** **** **** **** ****', startsWith: 'AT' },
    { mask: '** ** **** **** ****', startsWith: 'BE' },
    { mask: '** ** **** **** **** **** **', startsWith: 'BG' },
    { mask: '** ** **** **** **** **** **** ****', startsWith: 'CY' },
    { mask: '** ** **** **** **** **** *', startsWith: 'HR' },
    { mask: '** ** **** **** **** **', startsWith: 'DK' },
    { mask: '** ** **** **** **** **** ****', startsWith: 'ES' },
    { mask: '** ** **** **** **** ****', startsWith: 'EE' },
    { mask: '** ** **** **** **** **', startsWith: 'FI' },
    { mask: '** ** **** **** **** **** **** ***', startsWith: 'FR' },
    { mask: '** ** **** **** **** **** ***', startsWith: 'GI' },
    { mask: '** ** **** **** **** **** **** ***', startsWith: 'GR' },
    { mask: '** ** **** **** **** **', startsWith: 'GL' },
    { mask: '** ** **** **** **** **** **** ****', startsWith: 'HU' },
    { mask: '** ** **** **** **** **', startsWith: 'FO' },
    { mask: '** ** **** **** **** **** **', startsWith: 'IE' },
    { mask: '** ** **** **** **** **** **** **', startsWith: 'IS' },
    { mask: '** ** **** **** **** **** **** ***', startsWith: 'IT' },
    { mask: '** ** **** **** **** **** *', startsWith: 'LV' },
    { mask: '** ** **** **** **** **** **', startsWith: 'LI' },
    { mask: '** ** **** **** **** ****', startsWith: 'LT' },
    { mask: '** ** **** **** **** ****', startsWith: 'LU' },
    { mask: '** ** **** **** **** **** **** ***', startsWith: 'MT' },
    { mask: '** ** **** **** **** **** **** ***', startsWith: 'MC' },
    { mask: '** ** **** **** ****', startsWith: 'NO' },
    { mask: '** ** **** **** **** **', startsWith: 'NL' },
    { mask: '** ** **** **** **** **** **** ****', startsWith: 'PL' },
    { mask: '** ** **** **** **** **** **** *', startsWith: 'PT' },
    { mask: '** ** **** **** **** **** ****', startsWith: 'CZ' },
    { mask: '** ** **** **** **** **** ****', startsWith: 'RO' },
    { mask: '** ** **** **** **** **** **', startsWith: 'GB' },
    { mask: '** ** **** **** **** **** **** ***', startsWith: 'SM' },
    { mask: '** ** **** **** **** **** ****', startsWith: 'SK' },
    { mask: '** ** **** **** **** **** *', startsWith: 'SI' },
    { mask: '** ** **** **** **** **** ****', startsWith: 'SE' },
    { mask: '** ** **** **** **** **** *', startsWith: 'CH' },
  ].map((mask) => ({ ...mask, lazy: false })),
  // @see https://imask.js.org/guide.html#masked-dynamic
  dispatch: (appended: string, dynamicMasked: any) => {
    const countryId = (dynamicMasked.value + appended).substring(0, 2)

    return dynamicMasked.compiledMasks.find((m: any) =>
      countryId.startsWith(m.startsWith.substring(0, countryId.length))
    )
  },
}
