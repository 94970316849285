import { type ActionTree } from 'vuex'
import { RootState } from '../state'
import { type StepState } from './state'

const actions: ActionTree<StepState, RootState> = {
  setCurrentSection({ commit }, payload) {
    commit('setCurrentSection', payload)
  },
  setCurrentStep({ commit }, payload) {
    commit('setCurrentStep', payload)
  },
}

export default actions
