import { type MutationTree } from 'vuex'
import { type RootState } from './state'
import { type EkomiPayload } from '~/api-clients/ekomi'
import { type Offer } from '~/types/offers'

const mutations: MutationTree<RootState> = {
  setCounter: (state: RootState, value: number) => (state.counter = value),
  setEkomi: (state: RootState, value: EkomiPayload) => {
    state.ekomi = value
  },
  setOffers: (state: RootState, offers: Offer[]) => (state.offers = offers),
  setInsurers: (state: RootState, payload: RootState['insurers']) =>
    (state.insurers = payload),
  setFetchingOffers: (state: RootState, value: boolean) =>
    (state.fetchingOffers = value),
}

export default mutations
